import {observable} from "mobx";

export default class Store {
    @observable praxisUrl: string | null = null;

    public decodeDoctorUrl = (encodedDoctorUrl:string):string => {
        // FIXME: unfortunately the inverse of replace "-" by "/" is not unique,
        //  --> urls in the API must changed to use '/' instead of '-'
        return window.atob(encodedDoctorUrl).replace(/-/g, '/');
    }

    protected encodeUrl = (url?: string | null): string => {
        return url ? window.btoa(url) : "";
    };

    public getEncodedUrl = (): string => this.encodeUrl(this.praxisUrl);

}