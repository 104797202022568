import { MainStore } from "./MainStore";
import { PraxisStore } from "./PraxisStore";
import config from "../config/client";

const stores: { [index: string]: any } = {
  mainStore: new MainStore(config),
  praxisStore: new PraxisStore(config)
};

Object.keys(stores).forEach(
  (storeName: string): void => {
    stores[storeName].stores = stores;
  }
);

export default stores;
