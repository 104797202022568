import { observable, action, computed, runInAction, toJS } from "mobx";
import axios from "axios";
import Store from "./Store";

export class MainStore extends Store {
  public config: object;

  constructor(config: object) {
    super();
    this.config = config;
  }

  public stores!: any;
  @observable serverError: string | null = null;

  @observable getAppointmentDetailsError: string | null = null;

  @observable bookingCode: string | null = null;

  @observable loginServerError: string | null = null;

  @observable taskProcessingServerError: string | null = null;
  @observable appointmentData = null;

  @computed
  public get appointmentDetails(): string | null {
    return toJS(this.appointmentData);
  }

  @computed
  public get appointmentRequestServerError(): string | null {
    return this.serverError;
  }

  @action
  createAppointmentRequest = async (formValues: any) => {
    const valuesToSend = {
      acceptedPrivacyTerms: formValues.acceptedPrivacyTerms,
      acceptedServiceTerms: formValues.acceptedServiceTerms,
      email: formValues.email,
      firstName: formValues.firstName && formValues.firstName,
      lastName: formValues.lastName,
      insuranceType: formValues.insuranceType && formValues.insuranceType,
      birthday: formValues.birthday && formValues.birthday.toISOString(),
      insuranceAuthority: formValues.insuranceAuthority,
      phone: `${formValues.prefix}${formValues.phone}`,
      doctorUrl: this.stores.praxisStore.getEncodedUrl(),
      suggestion: {
        day: formValues.day.toISOString(),
        dayTime: formValues.dayTime
      },
      alternativeSuggestion: {
        day:
          formValues.alternativeAppointmentdate &&
          formValues.alternativeAppointmentdate.toISOString(),
        dayTime:
          formValues.alternativeAppointmentTime &&
          formValues.alternativeAppointmentTime
      }
    };

    try {
      this.serverError = null;
      await axios({
        method: "post",
        url: `${process.env.API}/appointment`,
        data: valuesToSend
      });
    } catch (e) {
      const { response } = e;
      this.serverError = response.statusText;
    }
  };

  @action
  login = async (loginValues: any) => {
    try {
      runInAction(() => {
        this.loginServerError = null;
      });

      const { data } = await axios({
        method: "POST",
        url: `${process.env.API}/appointment/sign-in`,
        data: {
          lastName: loginValues.lastName,
          bookingCode: loginValues.bookingCode
        },
        headers: {
          "Content-Type": "application/json"
        }
      });
      localStorage.setItem("token", JSON.stringify(data));
    } catch (e) {
      const { response } = e;

      runInAction(() => {
        this.loginServerError = response.statusText;
      });
    }
  };

  @action
  getAppointmentDetails = async (url: string) => {
    const token: any = localStorage.getItem("token");
    try {
      runInAction(() => {
        this.getAppointmentDetailsError = null;
      });

      const { data } = await axios({
        method: "get",
        url: `${process.env.API}/appointment/${this.encodeUrl(url)}`,
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`
        }
      });

      runInAction(() => {
        this.appointmentData = data;
      });
      if (!this.stores.praxisStore.praxisUrl) {
        this.stores.praxisStore.getPraxisDataFromApiUrlNotation(data.doctorUrl);
      }
    } catch (e) {
      const { response } = e;

      runInAction(() => {
        this.getAppointmentDetailsError = response.statusText;
      });
    }
  };

  @action
  appointmentAccept = async (url: string) => {
    const token: any = localStorage.getItem("token");
    try {
      runInAction(() => {
        this.taskProcessingServerError = null;
      });
      await axios({
        method: "get",
        url: `${process.env.API}/appointment/accept/${this.encodeUrl(url)}`,
        headers: { Authorization: `Bearer ${JSON.parse(token)}` }
      });
    } catch (e) {
      const { response } = e;

      runInAction(() => {
        this.taskProcessingServerError = response.statusText;
      });
    }
  };
  @action
  appointmentDecline = async (url: string) => {
    const token: any = localStorage.getItem("token");
    try {
      await axios({
        method: "get",
        url: `${process.env.API}/appointment/decline/${this.encodeUrl(url)}`,
        headers: { Authorization: `Bearer ${JSON.parse(token)}` }
      });
    } catch (e) {
      const { response } = e;

      runInAction(() => {
        this.taskProcessingServerError = response.statusText;
      });
    }
  };

  @action
  getLastData = async (url: string) => {
    await this.getAppointmentDetails(url);
  };
}
