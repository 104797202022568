import React from "react";
import { Col,Spin,Icon } from "antd";


 const mainSpin = (
   <Col span={24} className="spinner-container">
     <Spin indicator={<Icon spin style={{ fontSize: 45 }} type="loading" />} />
   </Col>
 );

 export default mainSpin;