import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import { BrowserRouter as Router } from "react-router-dom";
import { LocaleProvider } from "antd";
import { useTranslation } from "react-i18next";
import mainSpin from './common/main-spin';
import stores from "./store/index";
import de_DE from "antd/es/locale-provider/de_DE";
import en_GB from "antd/es/locale-provider/en_GB";
//import App from "./components/App/App";
import * as serviceWorker from "./worker/serviceWorker";
import "./index.scss";
import "./i18n/i18n";
const App = lazy(() => import("./components/App/App"));


const CurrentLanguageProvider = () => {
  const { t } = useTranslation();
  const languageObject = t("language") === "de" ? de_DE : en_GB;
  return (
    <LocaleProvider locale={languageObject}>
      <App />
    </LocaleProvider>
  );
};

ReactDOM.render(
  <Suspense fallback={mainSpin}>
    <Provider {...stores}>
      <Router>
        <CurrentLanguageProvider />
      </Router>
    </Provider>
  </Suspense>,
  document.getElementById("root")
);

serviceWorker.unregister();
