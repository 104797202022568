import { observable, action } from "mobx";
import axios from "axios";
import moment from "moment";
import Store from "./Store";

export class PraxisStore extends Store {
  public config: any;

  constructor(config: any) {
    super();
    this.config = config;
  }

  @observable currentPraxis: any | null = null;

  @observable unknownPraxis = false;
  @observable praxisLoadingFailed = false;
  @observable praxisIsLoading = false;
  @observable failedUrl: string | undefined = undefined;

  @action
  getPraxisData = (params: string) => {
    if (this.failedUrl === params) return;
    this.praxisLoadingFailed = false;
    this.unknownPraxis = false;
    if (params === this.praxisUrl && this.currentPraxis) return;
    const encodedUrl = this.encodeUrl(params);

    const storedData = localStorage.getItem(encodedUrl);

    if (storedData) {
      const praxisData = JSON.parse(storedData);
      if (moment(praxisData.ttl) > moment()) {
        this.currentPraxis = praxisData;
        this.praxisUrl = params;
      } else {
        this.fetchPraxisDataAndStoreIt(encodedUrl, params);
      }
    } else {
      this.fetchPraxisDataAndStoreIt(encodedUrl, params);
    }
  };

  public getPraxisDataFromApiUrlNotation = (encodedUrl: string) => {
    this.getPraxisData(this.decodeDoctorUrl(encodedUrl));
  };

  @action
  fetchPraxisDataAndStoreIt = async (encodedUrl: string, url: string) => {
    this.praxisIsLoading = true;

    try {
      const { data } = await axios({
        method: "get",
        url: `${this.config.doctorPub}/${encodedUrl}`
      });
      this.praxisIsLoading = false;
      this.currentPraxis = data;
      this.praxisUrl = url;
      localStorage.setItem(encodedUrl, JSON.stringify(data));
    } catch (e) {
      if (e.result && e.result.status === 404) {
        this.unknownPraxis = true;
      } else {
        this.praxisLoadingFailed = true;
      }
      this.failedUrl = url;
      this.currentPraxis = null;
    }
  };
}
